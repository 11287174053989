var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "used-coupon-card rounded-lg d-flex pa-1",
      attrs: { elevation: "2" }
    },
    [
      _c("div", { staticClass: "d-flex align-center" }, [
        _c("img", {
          staticClass: "coupon-img rounded-lg",
          attrs: { src: _vm.coupon.img ? _vm.coupon.img : "", alt: "" }
        })
      ]),
      _c(
        "div",
        {
          staticClass: "d-flex flex-column flex-grow-1 pl-3",
          staticStyle: { "max-width": "100%" }
        },
        [
          _vm.coupon.type == "discount"
            ? _c("div", { staticClass: "coupon-info pr-2" }, [
                _c("div", { staticClass: "coupon-name" }, [
                  _vm._v(" " + _vm._s(_vm.coupon.name) + " ")
                ]),
                _c(
                  "div",
                  {
                    staticClass:
                      "primary--text text-h4 font-weight-semibold flex-shrink-0 amount-type"
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.coupon.amount) +
                        _vm._s(_vm.coupon.amountType) +
                        " "
                    )
                  ]
                )
              ])
            : _vm._e(),
          _vm.coupon.type == "donation"
            ? _c("div", { staticClass: "donation-info" }, [
                _c("div", { staticClass: "my-2" }, [
                  _vm._v(" " + _vm._s(_vm.coupon.name) + " ")
                ]),
                _c("div", { staticClass: "donation-description pr-2" }, [
                  _vm._v(" " + _vm._s(_vm.coupon.description) + " ")
                ])
              ])
            : _vm._e(),
          _c("div", { staticClass: "my-2" }, [
            _vm.coupon.date
              ? _c("span", [
                  _vm._v(
                    _vm._s(
                      _vm.coupon.type == "donation"
                        ? _vm.$t("coupons.discounts.donationUsed")
                        : _vm.$t("coupons.discounts.used")
                    ) +
                      " " +
                      _vm._s(_vm.coupon.date)
                  )
                ])
              : _vm.coupon.redemptionDates
              ? _c("span", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.coupon.type == "donation"
                          ? _vm.$t("coupons.discounts.donationUsed")
                          : _vm.$t("coupons.discounts.used")
                      ) +
                      " " +
                      _vm._s(_vm.formattedRedemptionDates) +
                      " "
                  )
                ])
              : _c("span", [
                  _vm._v(_vm._s(_vm.$t("coupons.discounts.alreadyUsed")))
                ])
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }