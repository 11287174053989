<template>
  <v-card elevation="2" class="used-coupon-card rounded-lg d-flex pa-1">
    <div class="d-flex align-center">
      <img
        :src="coupon.img ? coupon.img : ''"
        alt=""
        class="coupon-img rounded-lg"
      />
    </div>
    <div class="d-flex flex-column flex-grow-1 pl-3" style="max-width: 100%;">
      <div class="coupon-info pr-2" v-if="coupon.type == 'discount'">
        <div class="coupon-name">
          {{ coupon.name }}
        </div>
        <div
          class="primary--text text-h4 font-weight-semibold flex-shrink-0 amount-type"
        >
          {{ coupon.amount }}{{ coupon.amountType }}
        </div>
      </div>
      <div class="donation-info" v-if="coupon.type == 'donation'">
        <div class="my-2">
          {{ coupon.name }}
        </div>
        <div class="donation-description pr-2">
          {{ coupon.description }}
        </div>
      </div>
      <div class="my-2">
        <span v-if="coupon.date"
          >{{
            coupon.type == "donation"
              ? $t("coupons.discounts.donationUsed")
              : $t("coupons.discounts.used")
          }}
          {{ coupon.date }}</span
        >
        <span v-else-if="coupon.redemptionDates">
          {{
            coupon.type == "donation"
              ? $t("coupons.discounts.donationUsed")
              : $t("coupons.discounts.used")
          }}
          {{ formattedRedemptionDates }}
        </span>
        <span v-else>{{ $t("coupons.discounts.alreadyUsed") }}</span>
      </div>
    </div>
  </v-card>
</template>
<style lang="scss">
.used-coupon-card {
  height: 110px;
  .coupon-img {
    width: 100px;
  }
  .coupon-info {
    overflow: hidden;
    border-bottom: 2px dashed $primary;
    flex-grow: 1;
    display: flex;
    align-items: center;
    flex-direction: column;
    .coupon-name {
      flex-grow: 1;
      font-weight: 600;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
  .amount-type {
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
  }

  .donation-info {
    border-bottom: 2px dashed $primary;
    .donation-description {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
</style>
<script>
export default {
  name: "UsedCouponCard",
  props: {
    coupon: { type: Object, required: true }
  },
  methods: {
    formatDate(dateString) {
      // Split the input date string (assuming "YYYY-DD-MM" format)
      const [year, month, day] = dateString.split("-");

      // Reformat the date to "DD/MM/YYYY"
      return `${day}/${month}/${year}`;
    }
  },
  computed: {
    formattedRedemptionDates() {
      if (!this.coupon || !this.coupon.redemptionDates) {
        return "";
      }

      const dates = this.coupon.redemptionDates;
      dates.forEach((el, index) => {
        console.log(el);
        console.log(this.$dayjs(el, "YYYY-DD-MM").format("DD/MM/YYYY"));
        dates[index] = this.formatDate(el);
      });

      if (dates.length > 3) {
        return `. . . ${dates.slice(-3).join(", ")} `;
      } else {
        return dates.join(", ");
      }
    }
  }
};
</script>
